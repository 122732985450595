<div class="wrapper">
    <div class="title">Tickets</div>
    <div class="counts">
        <div class="item">
            <div class="title">Logged</div>
            <div class="value">{{ tickets.summary.logged | number }}</div>
        </div>
        <div class="item">
            <div class="title">Resolved</div>
            <div class="value">{{ tickets.summary.resolved | number }}</div>
        </div>
    </div>
    <div class="progress">
        <div
            class="progress-bar"
            [ngStyle]="{ width: tickets.summary.percentage + '%' }"
        ></div>
    </div>
    <div class="tickets">
        <div class="ticket" *ngFor="let ticket of tickets?.list">
            <div class="left">
                <div class="title">{{ ticket.ticketName }}</div>
                <div class="details">
                    <span class="date">{{
                        ticket.lastActivityDateAt | date
                    }}</span>
                    <span class="dot" *ngIf="ticket.customerName"> .</span>
                    <span class="user">{{ ticket.customerName }}</span>
                </div>
            </div>
            <div class="right">
                <div
                    class="status"
                    [ngStyle]="{
                        'background-color': getTicketStatus(ticket.ticketStatus)
                            ?.bgColor,
                        
                    }"
                >
                    <div
                        class="text"
                        [ngStyle]="{
                            color: getTicketStatus(ticket.ticketStatus)?.color
                        }"
                    >
                        {{ getTicketStatus(ticket.ticketStatus)?.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="show-more" (click)="openDialog()" *ngIf="tickets?.list?.length">
        Show more
    </div>
</div>
