import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { AnalyticsService } from '../../../../services/analytics.service';
import { NgChartsModule } from 'ng2-charts';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { Analytics } from '../../../../models/analytics.model';

@Component({
    selector: 'app-call-overflow',
    standalone: true,
    imports: [
        FormsModule,
        NgChartsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
    ],
    templateUrl: './call-overflow.component.html',
    styleUrl: './call-overflow.component.scss',
})
export class CallOverflowComponent {
    @Input() startDate!: Date;
    @Input() endDate!: Date;

    lineChartData!: ChartConfiguration<'bar'>['data'];
    lineChartOptions!: ChartOptions<'bar'>;
    lineChartLegend = true;
    timeZoneOffset = new Date().getTimezoneOffset();
    constructor(private _analyticsService: AnalyticsService) {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.startDate && this.endDate) {
            const fromDate = Analytics.formatDate(this.startDate);
            const toDate = Analytics.formatDate(this.endDate);
            this.getCallsMade(fromDate, toDate, this.timeZoneOffset);
        }
    }

    getCallsMade(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): void {
        this._analyticsService
            .getCallsMade(fromDate, toDate, timeZoneOffset)
            .subscribe((response: any[]) => {
                const allLabels = response.map((item) => item.date);
                const dataPoints = response.map((item) => item.callsMade);

                this.lineChartData = {
                    labels: allLabels,
                    datasets: [
                        {
                            data: dataPoints,
                            label: 'Calls made',
                            borderColor: 'black',
                            backgroundColor: '#34A5A5',
                            barPercentage: 0.5, // Reduces the width of each bar
                            categoryPercentage: 0.8, // Increases the spacing between bars
                        },
                    ],
                };
                function formatDate(inputDate: number) {
                    const date = new Date(allLabels[inputDate]);
                    const monthNames = [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ];
                    const month = monthNames[date.getMonth()];
                    const day = date.getDate().toString().padStart(2, '0');
                    return `${month} ${day}`;
                }

                this.lineChartOptions = {
                    plugins: {
                        legend: {
                            display: false, // Hides the legend
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false, // Hides grid l9ines on the x-axis
                            },
                            ticks: {
                                callback: function (value, index) {
                                    // Assuming 'value' is the date in the "2023-12-08T00:00:00" format
                                    if (index % 3 === 1) {
                                        return formatDate(index);
                                    } else {
                                        return null; // Hides the tick
                                    }
                                },
                            },
                        },
                    },
                };
            });
    }
}
