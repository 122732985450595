import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { AnalyticsService } from '../../../../services/analytics.service';
import { NgChartsModule } from 'ng2-charts';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { Analytics } from '../../../../models/analytics.model';

@Component({
    selector: 'app-call-vs-time',
    standalone: true,
    imports: [
        FormsModule,
        NgChartsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
    ],
    templateUrl: './call-vs-time.component.html',
    styleUrl: './call-vs-time.component.scss',
})
export class CallVsTimeComponent {
    @Input() startDate!: Date;
    @Input() endDate!: Date;

    lineChartData!: ChartConfiguration<'bar'>['data'];
    lineChartOptions!: ChartOptions<'bar'>;

    lineChartLegend = true;
    currentDate!: Date;
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(private _analyticsService: AnalyticsService) {}

    ngOnInit(): void {
        // this.currentDate = new Date();
        // this.currentDate.setHours(0, 0, 0, 0);
        // this.onDateChange(this.currentDate);
    }

    ngOnChanges(): void {
        if (this.startDate && this.endDate) {
            const fromDate = Analytics.formatDate(this.startDate);
            const toDate = Analytics.formatDate(this.endDate);
            this.getCallsVsTime(fromDate, toDate, this.timeZoneOffset);
        }
    }

    getCallsVsTime(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): void {
        this._analyticsService
            .getCallsVsTime(fromDate, toDate, timeZoneOffset)
            .subscribe((response: any[]) => {
                const allLabels = response.map((item) => item.hour);
                const callsMade = response.map((item) => item.averageCalls);
                const callAnswered = response.map(
                    (item) => item.averageAnswered
                );

                this.lineChartData = {
                    labels: allLabels,
                    datasets: [
                        {
                            data: callAnswered,
                            label: 'Average Calls Answered',
                            backgroundColor: '#34A5A5',
                            barPercentage: 0.5,
                            categoryPercentage: 0.8,
                            stack: 'stack0', // Assigning a stack identifier
                        },
                        {
                            data: callsMade,
                            label: 'Average Calls',
                            backgroundColor: '#8BD5D4',
                            barPercentage: 0.5,
                            categoryPercentage: 0.8,
                            stack: 'stack0', // Same stack identifier for stacking
                        },
                    ],
                };
                function formatDate(inputDate: number) {
                    const date = allLabels[inputDate];

                    // Extracting hours and minutes from the time part
                    const hoursAndMinutes = date.substring(0, 5);
                    return hoursAndMinutes;
                }

                this.lineChartOptions = {
                    plugins: {
                        legend: {
                            display: false, // Hides the legend
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false, // Hides grid l9ines on the x-axis
                            },
                            stacked: true,
                            ticks: {
                                callback: function (value, index) {
                                    // Assuming 'value' is the date in the "2023-12-08T00:00:00" format
                                    if (index % 4 === 0) {
                                        return formatDate(index);
                                    } else {
                                        return null; // Hides the tick
                                    }
                                },
                            },
                        },
                        y: {
                            stacked: true,
                        },
                    },
                };
            });
    }

    // onDateChange(event: any) {
    //     const date = this.currentDate;

    //     let year = date.getFullYear();
    //     let month = date.getMonth() + 1; // getMonth() returns 0-11
    //     let day = date.getDate();

    //     let monthStr = month < 10 ? '0' + month : month.toString();
    //     let dayStr = day < 10 ? '0' + day : day.toString();

    //     this.getCallsVsTime(`${year}-${monthStr}-${dayStr}`);
    // }
}
