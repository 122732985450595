<div class="wrapper">
    <div class="title">Calls made</div>
    <div style="display: block">
        <canvas
            baseChart
            width="510"
            height="350"
            [type]="'bar'"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
        >
        </canvas>
    </div>
</div>
