import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import {
    SummaryResponse,
    TicketsResponse,
} from '../interfaces/analytics.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private http: HttpClient = inject(HttpClient);
    constructor() {}

    getTickets(
        pageIndex: number,
        pageSize: number,
        searchKey: string = '',
        timeZoneOffset: number
    ): Observable<TicketsResponse> {
        return this.http.get<TicketsResponse>(
            `${environment.apiBaseUrl}/api/dashboard/tickets?pageIndex=${pageIndex}&pageSize=${pageSize}&searchKey=${searchKey}&timeZone=${timeZoneOffset}`
        );
    }

    getContacts(
        pageIndex: number,
        pageSize: number,
        searchKey: string = '',
        timeZoneOffset: number
    ) {
        return this.http.get(
            `${environment.apiBaseUrl}/api/dashboard/contacts?pageIndex=${pageIndex}&pageSize=${pageSize}&searchKey=${searchKey}&timeZone=${timeZoneOffset}`
        );
    }

    getSummary(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): Observable<SummaryResponse> {
        return this.http.get<SummaryResponse>(
            `${environment.apiBaseUrl}/api/dashboard/summary?fromDate=${fromDate}&toDate=${toDate}&timeZone=${timeZoneOffset}`
        );
    }

    getCallHistory(
        pageIndex: number,
        pageSize: number,
        searchKey: string = '',
        timeZoneOffset: number
    ) {
        return this.http.get<any>(
            `${environment.apiBaseUrl}/api/dashboard/call-history?pageIndex=${pageIndex}&pageSize=${pageSize}&searchKey=${searchKey}&timeZone=${timeZoneOffset}`
        );
    }

    getCallsMade(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): Observable<any[]> {
        return this.http.get<any[]>(
            `${environment.apiBaseUrl}/api/dashboard/graph/calls-made?fromDate=${fromDate}&toDate=${toDate}&timeZone=${timeZoneOffset}`
        );
    }

    getCostBreakdown(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): Observable<any[]> {
        return this.http.get<any[]>(
            `${environment.apiBaseUrl}/api/dashboard/graph/cost-breakdown?fromDate=${fromDate}&toDate=${toDate}&timeZone=${timeZoneOffset}`
        );
    }

    getCallsVsTime(fromDate: string, toDate: string, timeZoneOffset: number) {
        return this.http.get<any[]>(
            `${environment.apiBaseUrl}/api/dashboard/graph/call-times?fromDate=${fromDate}&toDate=${toDate}&timeZone=${timeZoneOffset}`
        );
    }

    getCallResults(fromDate: string, toDate: string, timeZoneOffset: number) {
        return this.http.get<any[]>(
            `${environment.apiBaseUrl}/api/dashboard/graph/call-results?fromDate=${fromDate}&toDate=${toDate}&timeZone=${timeZoneOffset}`
        );
    }

    getCostAnalysis(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): Observable<any> {
        return this.http.get<any>(
            `${environment.apiBaseUrl}/api/dashboard/graph/cost-analysis?fromDate=${fromDate}&toDate=${toDate}&timeZone=${timeZoneOffset}`
        );
    }
}
