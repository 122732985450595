<div class="wrapper">
    <div class="top-row">
        <div class="title">Contacts</div>
        <div class="close" (click)="closeDialog()">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M18 6L6 18M6 6L18 18"
                    stroke="#4A4A48"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    </div>
    <div class="filter-row">
        <div class="search" [ngClass]="{ focused: isInputFocused }">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                    stroke="#B0B3B3"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <input
                type="text"
                placeholder="Search"
                [formControl]="searchInput"
                (focus)="onFocus()"
                (blur)="onBlur()"
            />
        </div>
    </div>
    <div class="table" (scroll)="onScroll($event)">
        <div class="table-header">
            <div class="th">Name</div>
            <div class="th">Phone number</div>
            <div class="th">Created date</div>
        </div>
        <div class="table-data">
            <div class="contact" *ngFor="let contact of contacts?.list">
                <div class="field">{{ contact.name }}</div>
                <div class="field">{{ contact.phoneNumber }}</div>
                <div class="field">{{ contact.createdDate | date }}</div>
            </div>
        </div>
    </div>
</div>
