<div class="wrapper">
    <div class="title-row">
        <div class="title">Call history log</div>
        <div class="filters">
            <div class="search" [ngClass]="{ focused: isInputFocused }">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#B0B3B3"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <input
                    type="text"
                    placeholder="Search"
                    #searchInput
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                />
            </div>
            <div class="filter">
                <div class="range">Any duration</div>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="#B0B3B3"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </div>
    </div>
    <div class="table">
        <div class="table-header">
            <div class="th ticket-number">Ticket number</div>
            <div class="th contact">Contact</div>
            <div class="th date-time">Date, time</div>
            <div class="th duration">Duration</div>
        </div>
        <audio
            #audioPlayer
            controls
            id="audioPlayer"
            hidden
            (timeupdate)="updatePlaybackPercentage()"
            (ended)="onPlaybackEnded()"
        >
            <source [src]="recordingUrl" type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>

        <div class="table-data">
            <div class="call-data" *ngFor="let call of callHistory; index as i">
                <div class="type field">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M5 15L15 5M15 5H8.33333M15 5V11.6667"
                            stroke="#5F9616"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div
                    class="ticket-number field"
                    [matTooltip]="call.ticketNumber"
                    matTooltipClass="custom-tooltip"
                >
                    {{ call.ticketNumber }}
                </div>
                <div class="contact field">
                    <div class="text">
                        {{ call.contactName || "unknown" }}
                    </div>
                    <span class="gray">{{ call.phoneNumber }}</span>
                </div>
                <div class="date-time field">
                    <div class="date">
                        {{ call.dateTime | date : "MMM d, y" }}
                    </div>
                    <div class="dot">.</div>
                    <div class="time">{{ call.dateTime | date : "HH:mm" }}</div>
                </div>
                <div class="duration field">
                    {{ getFormattedTime(call.duration) }}
                </div>
                <div class="actions field" *ngIf="call.recordingUrl">
                    <div
                        class="progress-bar"
                        [ngStyle]="{
                            background: gradientStyle()
                        }"
                        *ngIf="selectedRecordingIndex == i && call.isPlaying"
                    >
                        <progress
                            style="visibility: hidden; height: 0; width: 0"
                        ></progress>
                    </div>
                    <div
                        class="play"
                        (click)="toggleAudio(call.recordingUrl, i)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            *ngIf="!call.isPlaying"
                        >
                            <path
                                d="M4.16669 4.15803C4.16669 3.34873 4.16669 2.94407 4.33543 2.72101C4.48243 2.52669 4.70712 2.40644 4.95035 2.39192C5.22955 2.37525 5.56624 2.59971 6.23962 3.04863L15.0026 8.89061C15.559 9.26154 15.8372 9.44701 15.9341 9.68078C16.0189 9.88516 16.0189 10.1149 15.9341 10.3192C15.8372 10.553 15.559 10.7385 15.0026 11.1094L6.23962 16.9514C5.56624 17.4003 5.22955 17.6248 4.95035 17.6081C4.70712 17.5936 4.48243 17.4733 4.33543 17.279C4.16669 17.0559 4.16669 16.6513 4.16669 15.842V4.15803Z"
                                stroke="#4A4A48"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <svg
                            fill="#000000"
                            width="20"
                            height="20"
                            viewBox="-8.5 0 32 32"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            *ngIf="call.isPlaying"
                        >
                            <path
                                d="M8.2 22.48c-0.48 0-0.84-0.36-0.84-0.84v-11.28c0-0.48 0.36-0.84 0.84-0.84s0.84 0.36 0.84 0.84v11.32c0 0.44-0.36 0.8-0.84 0.8zM0.84 22.48c-0.48 0-0.84-0.36-0.84-0.84v-11.28c0-0.48 0.36-0.84 0.84-0.84s0.84 0.36 0.84 0.84v11.32c-0.040 0.44-0.36 0.8-0.84 0.8z"
                            ></path>
                        </svg>
                    </div>
                    <div
                        class="download"
                        (click)="
                            downloadAudio(call.recordingUrl, call.dateTime)
                        "
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                        >
                            <path
                                d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5"
                                stroke="#4A4A48"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination">
            <div class="left" (click)="previousPage()">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M12.5 5L7.5 10L12.5 15"
                        stroke="#4A4A48"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <div class="page-number">
                <input
                    type="number"
                    [(ngModel)]="pageNumber"
                    (change)="getCallHistory()"
                />
            </div>
            <div class="total-pages">of {{ totalPages }} pages</div>
            <div class="right" (click)="nextPage()">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M7.5 15L12.5 10L7.5 5"
                        stroke="#4A4A48"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </div>
    </div>
</div>
