export class Analytics {
    static formatDate(date: Date): string {
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let day = date.getDate();

        let monthStr = month < 10 ? '0' + month : month.toString();
        let dayStr = day < 10 ? '0' + day : day.toString();

        return `${year}-${monthStr}-${dayStr}`;
    }
}
