<div class="wrapper">
    <div class="title">
        Cost analysis
        <span class="date-range"
            >{{ startDate | date : "MMM d, y" }} -
            {{ endDate | date : "MMM d, y" }}</span
        >
    </div>
    <div class="details">
        <div class="detail">
            <div class="color green"></div>
            <div class="data">
                <div class="title">Cost total</div>
                <div class="amount">{{ summary?.totalCost | currency }}</div>
            </div>
        </div>
        <div class="detail">
            <div class="color pink"></div>
            <div class="data">
                <div class="title">Average</div>
                <div class="amount">{{ summary?.average | currency }}</div>
            </div>
        </div>
    </div>
    <div style="display: block">
        <canvas
            baseChart
            width="510"
            height="295"
            [type]="'line'"
            [data]="lineChartData"
            [options]="lineChartOptions"
        >
        </canvas>
    </div>
</div>
