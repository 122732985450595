import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private http: HttpClient,
        private cookieService: CookieService
    ) {}

    login(username: string, password: string): Observable<any> {
        return this.http
            .post(`${environment.apiBaseUrl}/api/auth/login`, {
                username,
                password,
            })
            .pipe(
                tap((res: any) => {
                    this.cookieService.set('access_token', res.access_token);
                })
            );
    }
}
