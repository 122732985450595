import { Component } from '@angular/core';
import { SummaryComponent } from './components/summary/summary.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CallsMadeComponent } from './components/calls-made/calls-made.component';
import { CallHistoryComponent } from './components/call-history/call-history.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { CostBreakdownComponent } from './components/cost-breakdown/cost-breakdown.component';
import { CallResultComponent } from './components/call-result/call-result.component';
import { CostAnalysisComponent } from './components/cost-analysis/cost-analysis.component';
import { CallVsTimeComponent } from './components/call-vs-time/call-vs-time.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from '../../shared/header/header.component';
import { DatePipe } from '@angular/common';
import { CallOverflowComponent } from './components/call-overflow/call-overflow.component';

@Component({
    selector: 'app-analytics',
    standalone: true,
    templateUrl: './analytics.component.html',
    styleUrl: './analytics.component.scss',
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-US' }],
    imports: [
        FormsModule,
        SummaryComponent,
        TicketsComponent,
        ContactsComponent,
        CallsMadeComponent,
        CallHistoryComponent,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        CostBreakdownComponent,
        CallResultComponent,
        CostAnalysisComponent,
        CallVsTimeComponent,
        HeaderComponent,
        DatePipe,
        CallOverflowComponent,
    ],
})
export class AnalyticsComponent {
    startDate!: Date;
    endDate!: Date;

    constructor() {
        this.startDate = new Date(
            new Date().setDate(new Date().getDate() - 30)
        );
        this.endDate = new Date();
    }
}
