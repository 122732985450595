import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [FormsModule],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
})
export class LoginComponent {
    username: string = '';
    password: string = '';
    constructor(private authService: AuthService, private router: Router) {}

    login(): void {
        this.authService.login(this.username, this.password).subscribe({
            next: (data) => {
                this.router.navigate(['/analytics']);
            },
            error: (error) => {
                // Handle error
                console.error('Login failed', error);
            },
        });
    }
}
