import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { AnalyticsService } from '../../../../services/analytics.service';
import { Analytics } from '../../../../models/analytics.model';

@Component({
    selector: 'app-cost-breakdown',
    standalone: true,
    imports: [NgChartsModule],
    templateUrl: './cost-breakdown.component.html',
    styleUrl: './cost-breakdown.component.scss',
})
export class CostBreakdownComponent {
    @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;

    @Input() startDate!: Date;
    @Input() endDate!: Date;

    lineChartData!: ChartConfiguration<'line'>['data'];
    lineChartOptions!: ChartOptions<'line'>;
    lineChartLegend = true;
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(private _analyticsService: AnalyticsService) {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.startDate && this.endDate) {
            const fromDate = Analytics.formatDate(this.startDate);
            const toDate = Analytics.formatDate(this.endDate);
            this.getCostBreakdown(fromDate, toDate, this.timeZoneOffset);
        }
    }

    getCostBreakdown(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): void {
        this._analyticsService
            .getCostBreakdown(fromDate, toDate, timeZoneOffset)
            .subscribe((response: any[]) => {
                const allLabels = response.map((item) => item.date);
                const dataPoints = response.map((item) => item.cost);

                const ctx = this.canvas.nativeElement.getContext('2d');

                // Create the gradient
                let gradient = ctx!.createLinearGradient(0, 0, 0, 400);
                gradient.addColorStop(0, 'rgba(52, 165, 165, 0.6)');
                gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

                this.lineChartData = {
                    labels: allLabels,
                    datasets: [
                        {
                            data: dataPoints,
                            label: 'Cost',
                            borderColor: '#34A5A5',
                            backgroundColor: gradient,
                            pointBackgroundColor: '#34A5A5',
                            pointBorderColor: '#34A5A5',
                            tension: 0.2,
                            fill: 'start',
                        },
                    ],
                };
                function formatDate(inputDate: number) {
                    const date = new Date(allLabels[inputDate]);
                    const monthNames = [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ];
                    const month = monthNames[date.getMonth()];
                    const day = date.getDate().toString().padStart(2, '0');
                    return `${month} ${day}`;
                }

                this.lineChartOptions = {
                    plugins: {
                        legend: {
                            display: false, // Hides the legend
                            position: 'bottom',
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false, // Hides grid l9ines on the x-axis
                            },
                            ticks: {
                                callback: function (value, index) {
                                    // Assuming 'value' is the date in the "2023-12-08T00:00:00" format
                                    if (index % 3 === 1) {
                                        return formatDate(index);
                                    } else {
                                        return null; // Hides the tick
                                    }
                                },
                            },
                        },
                        y: {
                            ticks: {
                                callback: function (value) {
                                    return `$${value}`;
                                },
                            },
                        },
                    },
                };
            });
    }
}
