import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { AnalyticsService } from '../../../../services/analytics.service';
import { Analytics } from '../../../../models/analytics.model';

@Component({
    selector: 'app-call-result',
    standalone: true,
    imports: [NgChartsModule],
    templateUrl: './call-result.component.html',
    styleUrl: './call-result.component.scss',
})
export class CallResultComponent {
    @Input() startDate!: Date;
    @Input() endDate!: Date;

    lineChartData!: ChartConfiguration<'pie'>['data'];
    lineChartOptions!: ChartOptions<'pie'>;
    lineChartLegend = true;
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(private _analyticsService: AnalyticsService) {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.startDate && this.endDate) {
            const fromDate = Analytics.formatDate(this.startDate);
            const toDate = Analytics.formatDate(this.endDate);
            this.getCallResults(fromDate, toDate, this.timeZoneOffset);
        }
    }

    getCallResults(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): void {
        this._analyticsService
            .getCallResults(fromDate, toDate, timeZoneOffset)
            .subscribe((response: any[]) => {
                // Extract labels and data points for pie chart
                const pieChartLabels = response.map((item) => item.status);
                const pieChartDataPoints = response.map((item) => item.count);

                // Define the pie chart data
                this.lineChartData = {
                    labels: pieChartLabels,
                    datasets: [
                        {
                            data: pieChartDataPoints,
                            backgroundColor: [
                                '#34A5A5',
                                '#64BDBC',
                                '#8BD5D4',
                                '#B1EEED',
                            ], // Array of colors for each segment
                            hoverBackgroundColor: [
                                '#34A5A5',
                                '#64BDBC',
                                '#8BD5D4',
                                '#B1EEED',
                            ], // Optional: colors when hovering over segments
                        },
                    ],
                };

                // Define pie chart options
                this.lineChartOptions = {
                    plugins: {
                        legend: {
                            display: true, // Show the legend
                        },
                    },
                    responsive: true, // Makes the chart responsive
                    maintainAspectRatio: false, // Optional: change aspect ratio
                };
            });
    }
}
