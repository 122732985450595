import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AnalyticsService } from '../../../../services/analytics.service';
import { SummaryResponse } from '../../../../interfaces/analytics.interface';
import { Analytics } from '../../../../models/analytics.model';

@Component({
    selector: 'app-summary',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './summary.component.html',
    styleUrl: './summary.component.scss',
})
export class SummaryComponent {
    @Input() startDate!: Date;
    @Input() endDate!: Date;

    summaryObj: SummaryResponse;
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(private _analyticsService: AnalyticsService) {
        this.summaryObj = {
            totalCalls: 0,
            totalMinutes: 0,
            totalCost: 0,
            averageCost: 0,
        };
    }

    ngOnInit() {}

    ngOnChanges(): void {
        if (this.startDate && this.endDate) {
            const fromDate = Analytics.formatDate(this.startDate);
            const toDate = Analytics.formatDate(this.endDate);
            this.getSummary(fromDate, toDate, this.timeZoneOffset);
        }
    }

    getSummary(fromDate: string, toDate: string, timeZoneOffset: number): void {
        this._analyticsService
            .getSummary(fromDate, toDate, timeZoneOffset)
            .subscribe((data: SummaryResponse) => {
                this.summaryObj = data;
            });
    }
}
