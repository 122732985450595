import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketsResponse } from '../../../../interfaces/analytics.interface';
import { TicketsConstant } from '../../../../constants/analytics.constant';
import { AnalyticsService } from '../../../../services/analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { TicketsPopupComponent } from '../tickets-popup/tickets-popup.component';

@Component({
    selector: 'app-tickets',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './tickets.component.html',
    styleUrl: './tickets.component.scss',
})
export class TicketsComponent {
    tickets!: TicketsResponse;
    pageIndex: number = 0;
    searchKey: string = '';
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(
        public dialog: MatDialog,
        private _analyticsService: AnalyticsService
    ) {
        this.tickets = {
            list: [],
            summary: {
                logged: 0,
                resolved: 0,
                percentage: 0,
            },
        };
    }

    ngOnInit(): void {
        this.getTickets(
            this.pageIndex,
            TicketsConstant.DEFAULT_TICKET_COUNT,
            this.searchKey,
            this.timeZoneOffset
        );
    }

    getTickets(
        pageIndex: number,
        pageSize: number,
        searchKey: string,
        timeZoneOffset: number
    ): void {
        this._analyticsService
            .getTickets(pageIndex, pageSize, searchKey, timeZoneOffset)
            .subscribe((response: any) => {
                this.tickets = response;
            });
    }

    getTicketStatus(status: number) {
        return TicketsConstant.TICKET_STATUS.find((item) => item.id === status);
    }

    openDialog(): void {
        const width = window.innerWidth <= 500 ? '100vw' : '486px';
        this.dialog.open(TicketsPopupComponent, {
            width: width,
            position: { right: '0px' },
            panelClass: 'custom-dialog-container',
        });
    }
}
