<div class="wrapper">
    <div class="title">Contacts</div>
    <div class="counts">
        <!-- <div class="item">
            <div class="title">Call batches</div>
            <div class="value">
                {{ contacts?.summary?.callBatches | number }}
            </div>
        </div> -->
        <div class="item">
            <div class="title">Total contacts</div>
            <div class="value">
                {{ contacts?.summary?.totalContacts | number }}
            </div>
        </div>
    </div>
    <div class="table desktop">
        <div class="table-header">
            <div class="th">Name</div>
            <div class="th">Phone number</div>
            <div class="th">Created date</div>
        </div>
        <div class="table-data">
            <div class="contact" *ngFor="let contact of contacts.list">
                <div class="field">{{ contact.name }}</div>
                <div class="field">{{ contact.phoneNumber }}</div>
                <div class="field">{{ contact.createdDate | date }}</div>
            </div>
        </div>
    </div>
    <div class="table mobile">
        <div class="contact" *ngFor="let contact of contacts.list">
            <div class="name">{{ contact.name }}</div>

            <div class="details">
                <span class="number">{{ contact.phoneNumber }}</span>
                <span class="dot" *ngIf="contact.createdDate"> .</span>
                <span class="date">{{ contact.createdDate | date }}</span>
            </div>
        </div>
    </div>
    <div class="show-more" (click)="openDialog()" *ngIf="contacts?.list.length">
        Show more
    </div>
</div>
