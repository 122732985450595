import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { TicketsResponse } from '../../../../interfaces/analytics.interface';
import { AnalyticsService } from '../../../../services/analytics.service';
import { TicketsConstant } from '../../../../constants/analytics.constant';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
@Component({
    selector: 'app-tickets-popup',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
    templateUrl: './tickets-popup.component.html',
    styleUrl: './tickets-popup.component.scss',
})
export class TicketsPopupComponent {
    searchInput = new FormControl('');
    isInputFocused: boolean = false;
    tickets: TicketsResponse;
    pageIndex: number = 0;
    loading: boolean = false;
    searchKey: string = '';
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(
        public dialogRef: MatDialogRef<TicketsPopupComponent>,
        private _analyticsService: AnalyticsService
    ) {
        this.tickets = {
            list: [],
            summary: {
                logged: 0,
                resolved: 0,
                percentage: 0,
            },
        };
    }

    ngOnInit(): void {
        this.getTickets(
            this.pageIndex,
            TicketsConstant.POPUP_TICKET_COUNT,
            this.searchKey,
            this.timeZoneOffset
        );
        this.searchInput.valueChanges
            .pipe(
                debounceTime(500), // Wait for 500ms pause in events
                distinctUntilChanged() // Only emit if value is different from previous value
            )
            .subscribe((value) => {
                this.searchKey = value || '';
                this.pageIndex = 0;
                this.tickets.list = [];
                this.getTickets(
                    this.pageIndex,
                    TicketsConstant.POPUP_TICKET_COUNT,
                    this.searchKey,
                    this.timeZoneOffset
                );
            });
    }
    closeDialog(): void {
        this.dialogRef.close();
    }

    onFocus() {
        this.isInputFocused = true;
    }

    onBlur() {
        this.isInputFocused = false;
    }

    getTickets(
        pageIndex: number,
        pageSize: number,
        searchKey: string = '',
        timeZoneOffset: number
    ): void {
        this.loading = true;
        this._analyticsService
            .getTickets(pageIndex, pageSize, searchKey, timeZoneOffset)
            .subscribe((response: TicketsResponse) => {
                if (response) {
                    this.tickets.list = [
                        ...this.tickets.list,
                        ...response.list,
                    ];
                    this.loading = false;
                }
            });
    }

    getTicketStatus(status: number) {
        return TicketsConstant.TICKET_STATUS.find((item) => item.id === status);
    }

    onScroll(event: any): void {
        const element = event.target;

        if (
            element.scrollTop + element.clientHeight >=
            element.scrollHeight - 5
        ) {
            this.pageIndex++;
            this.getTickets(
                this.pageIndex,
                TicketsConstant.POPUP_TICKET_COUNT,
                this.searchKey,
                this.timeZoneOffset
            );
        }
    }
}
