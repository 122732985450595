import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from '../../../../services/analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactsPopupComponent } from '../contacts-popup/contacts-popup.component';

@Component({
    selector: 'app-contacts',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './contacts.component.html',
    styleUrl: './contacts.component.scss',
})
export class ContactsComponent {
    contacts: any = [];
    searchKey: string = '';
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(
        public dialog: MatDialog,
        private _analyticsService: AnalyticsService
    ) {
        this.contacts = {
            summary: {
                callBatches: 0,
                totalContacts: 0,
            },
            list: [],
        };
    }

    ngOnInit(): void {
        this.getContactsList();
    }

    getContactsList() {
        this._analyticsService
            .getContacts(0, 6, this.searchKey, this.timeZoneOffset)
            .subscribe((response: any) => {
                this.contacts = response;
            });
    }

    openDialog(): void {
        const width = window.innerWidth <= 500 ? '100vw' : '486px';
        this.dialog.open(ContactsPopupComponent, {
            width: width,
            position: { right: '0px' },
            panelClass: 'custom-dialog-container',
        });
    }
}
