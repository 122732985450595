<section class="forms">
    <div class="container">
        <div class="forms-grid">
            <div class="login">
                <span class="fas fa-sign-in-alt"></span>
                <strong>Welcome!</strong>
                <span>Sign in to your account</span>

                <div>
                    <fieldset>
                        <div class="form">
                            <div class="form-row">
                                <span class="fas fa-user"></span>
                                <label class="form-label" for="input"
                                    >Username</label
                                >
                                <input
                                    type="text"
                                    class="form-text"
                                    name="username"
                                    [(ngModel)]="username"
                                />
                            </div>
                            <div class="form-row">
                                <span class="fas fa-eye"></span>
                                <label class="form-label" for="input"
                                    >Password</label
                                >
                                <input
                                    type="password"
                                    class="form-text"
                                    [(ngModel)]="password"
                                />
                            </div>

                            <div class="form-row button-login">
                                <button class="btn btn-login" (click)="login()">
                                    Login
                                    <span class="fas fa-arrow-right"></span>
                                </button>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</section>
