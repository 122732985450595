export class TicketsConstant {
    public static TICKET_STATUS = [
        {
            id: 1,
            name: 'New',
            bgColor: '#C583E3',
            color: '#FFFFFF',
        },
        {
            id: 5,
            name: 'Complete',
            bgColor: '#2297ED',
            color: '#FFFFFF',
        },
        {
            id: 7,
            name: 'Waiting Customer',
            bgColor: '#63CDD4',
            color: '#FFFFFF',
        },
        {
            id: 8,
            name: 'In Progress',
            bgColor: '#0CC433',
            color: '#FFFFFF',
        },
        {
            id: 10,
            name: 'Assigned/Scheduled',
            bgColor: '#F4B900',
            color: '#FFFFFF',
        },
        {
            id: 12,
            name: 'On Hold -Waiting Other',
            bgColor: '#666666',
            color: '#FFFFFF',
        },
        {
            id: 19,
            name: 'Customer Replied',
            bgColor: '#F4B900',
            color: '#FFFFFF',
        },
        {
            id: 20,
            name: 'Pending Complete',
            bgColor: '#3E6CA4',
            color: '#FFFFFF',
        },
        {
            id: 21,
            name: 'On Hold -Waiting Vendor',
            bgColor: '#666666',
            color: '#FFFFFF',
        },
    ];

    public static DEFAULT_TICKET_COUNT = 4;
    public static POPUP_TICKET_COUNT = 24;
}
