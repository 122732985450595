import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService, private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Retrieve the token from cookies
        const token = this.cookieService.get('access_token');
        if (token) {
            // If the token exists, clone the request to include the authorization header
            const clonedRequest = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return next.handle(clonedRequest).pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        console.log('401 error');
                        // Handle 401 error
                        this.handle401Error();
                    }
                    return throwError(() => error);
                })
            );
        }
        // If no token, forward the request as is
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    console.log('401 error');

                    // Handle 401 error
                    this.handle401Error();
                }
                return throwError(() => error);
            })
        );
    }

    private handle401Error(): void {
        // Clear the authentication token or cookies
        this.cookieService.delete('token');
        // Redirect to the login page or any other page
        this.router.navigate(['/login']);
    }
}
