<div class="wrapper">
    <div class="title">Calls results</div>
    <div style="display: block">
        <canvas
            baseChart
            [width]="370"
            [height]="370"
            [type]="'pie'"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
        >
        </canvas>
    </div>
</div>
