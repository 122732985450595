<div class="wrapper">
    <div class="title-row">
        <div class="title">Calls vs time</div>
        <!-- <div class="date-wrapper">
            <mat-form-field appearance="fill" class="date-picker">
                <mat-datepicker-toggle
                    matPrefix
                    [for]="picker"
                ></mat-datepicker-toggle>

                
                <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder="Choose a date"
                    [(ngModel)]="currentDate"
                    (dateChange)="onDateChange($event)"
                />
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div> -->
    </div>
    <div style="display: block">
        <canvas
            baseChart
            width="510"
            height="350"
            [type]="'bar'"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
        >
        </canvas>
    </div>
</div>
