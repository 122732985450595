import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { AnalyticsService } from '../../../../services/analytics.service';
import { CommonModule } from '@angular/common';
import { Analytics } from '../../../../models/analytics.model';

@Component({
    selector: 'app-cost-analysis',
    standalone: true,
    imports: [CommonModule, NgChartsModule],
    templateUrl: './cost-analysis.component.html',
    styleUrl: './cost-analysis.component.scss',
})
export class CostAnalysisComponent {
    @Input() startDate!: Date;
    @Input() endDate!: Date;

    lineChartData!: ChartConfiguration<'line'>['data'];
    lineChartOptions!: ChartOptions<'line'>;

    lineChartLegend = true;
    summary: any;
    timeZoneOffset = new Date().getTimezoneOffset();

    constructor(private _analyticsService: AnalyticsService) {}

    ngOnInit(): void {}

    ngOnChanges(): void {
        if (this.startDate && this.endDate) {
            const fromDate = Analytics.formatDate(this.startDate);
            const toDate = Analytics.formatDate(this.endDate);
            this.getCostAnalysis(fromDate, toDate, this.timeZoneOffset);
        }
    }

    getCostAnalysis(
        fromDate: string,
        toDate: string,
        timeZoneOffset: number
    ): void {
        this._analyticsService
            .getCostAnalysis(fromDate, toDate, timeZoneOffset)
            .subscribe((response: any) => {
                this.summary = response.summary;

                const data: any[] = response.data;
                const allLabels = data.map((item) => item.date);
                const cost = data.map((item) => item.cost);
                const averageCost = data.map((item) => item.averageCost);

                this.lineChartData = {
                    labels: allLabels,
                    datasets: [
                        {
                            data: cost,
                            label: 'Cost',
                            borderColor: '#34A5A5',
                            pointBackgroundColor: '#34A5A5',
                            // backgroundColor: '#34A5A5',
                            // barPercentage: 0.5, // Reduces the width of each bar
                            // categoryPercentage: 0.8, // Increases the spacing between bars
                        },
                        {
                            data: averageCost,
                            label: 'Average Cost',
                            borderColor: '#9e007f',
                            pointBackgroundColor: '#9e007f',
                            // backgroundColor: '#9E007F',
                            // barPercentage: 0.5, // Reduces the width of each bar
                            // categoryPercentage: 0.8, // Increases the spacing between bars
                        },
                    ],
                };
                function formatDate(inputDate: number) {
                    const date = new Date(allLabels[inputDate]);
                    const monthNames = [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ];
                    const month = monthNames[date.getMonth()];
                    const day = date.getDate().toString().padStart(2, '0');
                    return `${month} ${day}`;
                }

                this.lineChartOptions = {
                    plugins: {
                        legend: {
                            display: false, // Hides the legend
                        },
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false, // Hides grid l9ines on the x-axis
                            },
                            ticks: {
                                callback: function (value, index) {
                                    // Assuming 'value' is the date in the "2023-12-08T00:00:00" format
                                    return formatDate(index);
                                    if (index % 3 === 1) {
                                        return formatDate(index);
                                    } else {
                                        return null; // Hides the tick
                                    }
                                },
                            },
                        },
                        y: {
                            ticks: {
                                callback: function (value) {
                                    return `$${value}`;
                                },
                            },
                        },
                    },
                };
            });
    }
}
