<div class="wrapper">
    <div class="title-row">
        <div class="title">Cost breakdown over time periods</div>
    </div>
    <div style="display: block">
        <canvas
            #canvas
            baseChart
            width="510"
            height="392"
            [type]="'line'"
            [data]="lineChartData"
            [options]="lineChartOptions"
        >
        </canvas>
    </div>
</div>
